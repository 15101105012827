import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Header from "../../components/Header/index"
import SEO from "../../components/seo"
import { withPreview } from "gatsby-source-prismic"

const Glossary = ({ data }) => {
  const section = data.prismicGlossaryPage.data
  const heroImage = section.hero_banner_image.fluid.src
  const alpha = data.allPrismicGlossaryEntry.edges
    .map(item => {
      const term = item.node.data.title.html.replace(/<\/?[^>]+(>|$)/g, "")
      return term.substring(0, 1)
    })
    .filter(function(value, index, self) {
      return self.indexOf(value) === index
    })

  const filterItem = item => {
    const val = "glossaryTerm-" + item.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 1)
    return val
  }
  const countMatches = item => {
    var arr = data.allPrismicGlossaryEntry.edges.filter(
      obj => obj.node.data.title.html.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 1) === item,
    )
    var count = arr.length
    return count
  }

  return (
    <Layout>
      <SEO title={section.page_title} />
      <Header image={heroImage} title={section.hero_title.text} />
      <section className={`uk-section`}>
        <div className="uk-container" data-uk-filter="target:.js-filter">
          <div
            style={{ border: "1px solid #ccc", paddingLeft: "20px", paddingRight: "20px" }}
            className="uk-width-1-1 uk-flex uk-flex-row uk-flex-wrap uk-flex-middle uk-flex-around uk-border-rounded"
          >
            <div className="uk-active" data-uk-filter-control="">
              <div className="filter-selector uk-h4 uk-border-rounded">ALL</div>
            </div>
            {alpha.map((a, i) => (
              <div
                key={`letter-${a}`}
                className="uk-display-inline uk-padding-small"
                data-uk-filter-control={`filter: [data-tag='glossaryTerm-${a}']`}
              >
                <div className="filter-selector uk-h4 uk-border-rounded">
                  {a.toUpperCase()}
                  <span className="counter">{countMatches(a)}</span>
                </div>
              </div>
            ))}
          </div>

          <ul className="js-filter uk-list-divider uk-list uk-margin-large-top">
            {data.allPrismicGlossaryEntry.edges.map((item, i) => (
              <li
                key={`term-${i}`}
                data-tag={filterItem(item.node.data.title.html)}
                className={filterItem(item.node.data.title.html)}
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
              >
                <h3>{item.node.data.title.text}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.node.data.body.html }} />
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Glossary)

export const glossaryQuery = graphql`
  {
    prismicGlossaryPage {
      uid
      data {
        page_title
        hero_title {
          text
        }
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
      }
    }
    allPrismicGlossaryEntry(sort: { order: ASC, fields: data___title___text }) {
      edges {
        node {
          data {
            body {
              html
              text
            }
            title {
              html
              text
            }
          }
        }
      }
    }
  }
`
